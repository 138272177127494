import React from 'react';

const Arrow = () => (
  <svg
    width="24"
    height="14"
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.29834 7H22.2983M22.2983 7L17.5841 1M22.2983 7L17.5841 13"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

export default Arrow;
