import React from 'react';
import MediaQuery from 'react-responsive';

import HeaderMainItem from 'components/HeaderMainItem/HeaderMainItem';
import Text from 'components/UI/Text/Text';
import Slider from 'components/UI/Slider/Slider';

import {
  StyledLink,
  StyledHeaderMain,
  StyledSlider,
  StyledHeroItem,
  StyledListWrapper,
  StyledList,
} from './styled';

const HEADER_MAIN_ITEM = [
  {
    title: 'Open innovation',
    text:
      'Through our Open Innovation model, we guarantee the best match. We have strong relations with Tech providers and Corporates understanding their business.',
    link: 'Explore more',
    href: '/innovation',
  },
  {
    title: 'Sales',
    text:
      'Thanks to our expertise, contacts & unique insights, we offer the complete solution for companies wishing to enter new markets.',
    link: 'Explore more',
    href: '/sales',
  },
  {
    title: 'Funding',
    text:
      'We look for scalable results-driven startups and help founders transform their early-stage startups into globally scalable companies. We follow and support you step by step, from scouting to exit.',
    link: 'Explore more',
    href: '/funding',
  },
];
const businessColor = {
  Funding: 'rgba(24, 50, 171, 0.87)',
  Sales: 'rgba(242, 80, 24, 0.87)',
  "Open Innovation": 'rgba(51, 223, 255, 0.87)'
};

const HeaderMain = ({data}) => {
  const heroes = data.map(({node}) => ({
    title: node.heroText,
    image: node.heroImage.file.url,
    typeOfBusiness: node.typeOfBusiness,
    link: node.link,
  }));

  return (
    <StyledHeaderMain>
      <StyledSlider>
        <MediaQuery query={`(max-width: 768px)`}>
          {small => (
            <MediaQuery query={`(min-width: 768px) and (max-width: 1024px)`}>
              {middle => {
                let controlsStyle = '';

                // if (small) {
                if (middle) {
                  controlsStyle = 'top: 30% !important';
                } else {
                  controlsStyle = 'top: 40% !important';
                }
                //   controlsStyle = 'top: 200px !important';
                // } else {
                //   controlsStyle = 'top: 200px !important';
                // }

                return (
                  <Slider
                    rows={1}
                    slidesPerRow={1}
                    responsive={[]}
                    clearPadding
                    controlsStyle={controlsStyle}
                  >
                    {heroes.map(({ title, image, typeOfBusiness, link}) => (
                      <StyledHeroItem key={title} bg={image} >
                        <Text as="h1" centered bgcolor={businessColor[typeOfBusiness]}>
                        <a style={{color:'white'}} href={link}>{title}</a>
                        </Text>
                      </StyledHeroItem>
                    ))}
                  </Slider>
                )
              }}
            </MediaQuery>
          )}
        </MediaQuery>
      </StyledSlider>
      <StyledListWrapper>
        <StyledList>
          {HEADER_MAIN_ITEM.map(item => (
            <HeaderMainItem key={item.title} {...item} />
          ))}
        </StyledList>
      </StyledListWrapper>
    </StyledHeaderMain>
  );
};

export default HeaderMain;
