import React from 'react';

import Slider from 'components/UI/Slider/Slider';
import Arrow from './icons/Arrow';

import {
  StyledSection,
  StyledTitle,
  StyledItem,
  StyledButton,
  StyledText,
  StyledImg,
} from './styled';

const Partners = ({ data }) => {
  return (
    <StyledSection promo fullWidth>
      <StyledTitle as="h3" dark centered>
        Our Partners and Alliances
      </StyledTitle>
      <Slider>
        {data.map(({ node: { name, logoInSvg } }) => {
          const { url } = logoInSvg.file;

          return (
            <StyledItem key={url}>
              <StyledImg src={url} alt={name} />
            </StyledItem>
          );
        })}
      </Slider>
      <StyledButton as="a" variant="primary" href="/clients">
        <StyledText>See all</StyledText>
        <Arrow />
      </StyledButton>
    </StyledSection>
  );
};

export default Partners;
