import styled from 'styled-components';
import Text from 'components/UI/Text/Text';
import Link from 'components/UI/Link/Link';
import MEDIA from 'helpers/mediaTemplates';

export const StyledItem = styled.li`
  position: relative;

  padding-top: 2rem;
  margin-bottom: 2.4rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: #33dfff;
  }

  &:nth-child(2)::before {
    background-color: #f25018;
  }

  &:last-child::before {
    background-color: #1832ab;
  }

  &:hover p,
  &:hover a {
    display: inline-block;
  }

  &:first-child a:hover {
    color: #33dfff;

    &::before {
      background-color: #33dfff;
    }
  }

  &:nth-child(2) a:hover {
    color: #f25018;

    &::before {
      background-color: #f25018;
    }
  }

  &:last-child a:hover {
    color: #1832ab;

    &::before {
      background-color: #1832ab;
    }
  }

  ${MEDIA.TABLET_XL`
    min-height: 100px;
    width: 31.4%;
    padding: 3rem 1rem;
    background-image: linear-gradient(rgba(100,100,100,.1), rgba(0,0,0,.9));
    margin-bottom: 0;
  `}

  ${MEDIA.TABLET_XL`
    &::before {
      height: 0.8rem;
    }
  `}
`;

export const StyledTitle = styled(Text)`
  color: #ffffff;
`;

export const StyledText = styled.p`
  display: none;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 2.4rem;
  margin-top: 0.8rem;

  color: #ffffff;

  ${MEDIA.TABLET_XL`
    font-weight: 400;
    line-height: 2rem;
    font-size: 1.4rem;
  `}
`;

export const StyledLink = styled(Link)`
  color: #ffffff;
  display: none;

  ${MEDIA.TABLET_XL`
    position: relative;
    padding-bottom: 0.5rem;
    font-weight: 700;
    line-height: 2rem;
    font-size: 1.4rem;
    text-decoration: none;
    transition: all 0.1s linear;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 0.1rem;
      background-color: #ffffff;
  }
  `}
`;
