import React from 'react';

import Slider from 'components/UI/Slider/Slider';
import Arrow from './icons/Arrow';

import {
  StyledClients,
  StyledItem,
  StyledTitle,
  StyledWrapper,
  StyledButton,
  StyledText,
  StyledImg,
} from './styled';

const Clients = ({ data }) => {
  return (
    <StyledClients>
      <StyledWrapper>
        <StyledTitle as="h3" dark centered>
          Current Portfolio
        </StyledTitle>
        <Slider>
          {data.map(({ node }) => (
            <StyledItem key={node.name}>
              <StyledImg src={node.logoInSvg.file.url} alt={node.name} />
            </StyledItem>
          ))}
        </Slider>
        <StyledButton as="a" variant="primary" href="/clients">
          <StyledText>See all</StyledText>
          <Arrow />
        </StyledButton>
      </StyledWrapper>
    </StyledClients>
  );
};

export default Clients;
