import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const StyledList = styled.ul`
  margin-top: 70px;
  padding-left: 0.8rem;
  list-style: none;

  ${MEDIA.TABLET_XL`
    overflow-y: auto
    display: block;
    padding-left: 1.2rem;
    margin-bottom: 2rem;
    width: 100%;
    height: 300px;
    white-space: nowrap;
  `}
`;

export const StyledItem = styled.li`
  position: relative;
  padding: 1.6rem 0 3.2rem 3.2rem;
  color: #141414;

  &:last-child {
    font-weight: 400;
  }

  &:not(:last-child) {
    border-left: 0.1rem solid #d2d2d2;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 0.1rem;
    width: 7rem;
    background-color: #dedede;
  }

  &::after {
    content: '';
    position: absolute;
    top: -0.8rem;
    left: -0.8rem;
    height: 1.6rem;
    width: 1.6rem;
    background-color: #f25018;
    border-radius: 50%;
  }

  ${MEDIA.TABLET_XL`
    display: inline-block;
    width: 25%;
    padding: 4.2rem 0 4.2rem 2.4rem;
    border-bottom: 0.2rem solid #d2d2d2;

    &:not(:last-child) {
      border-left: none;
    }

    &:last-child {
      padding-bottom: 4rem;
      width: 50%;
      white-space: normal;
    }

    &::before {
      top: auto;
      bottom: 0;
      width: 0.1rem;
      height: 8.5rem;
    }

    &::after {
      top: auto;
      left: -1.2rem;
      bottom: -1.2rem;
      height: 2.4rem;
      width: 2.4rem;
    }
      &:nth-child(2n) {
        display: inline-flex;
        flex-direction: column;
        position: relative;
        top: 102px;
        border-bottom: none;
        border-top 0.2rem solid #d2d2d2;

        & p {
          order: 2;
          margin-bottom: 0;
          margin-top: 0.4rem;
        }

        &::before {
          top: 0;
          bottom: auto;
        }

        &::after {
          top: -1.2rem;
          bottom: auto;
        }
    }
  `}
`;

export const StyledYear = styled.p`
  margin-bottom: 0.4rem;
  font-weight: 400;
  line-height: 2rem;
  font-size: 1.4rem;
  color: rgba(24, 20, 24, 0.4);
`;

export const StyledText = styled.p`
  font-weight: 400;
`;
