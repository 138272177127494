import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

const Input = styled.input`
  width: 100%;
  margin-top: 0.8rem;
  padding: 1.6rem;
  font: inherit;
  font-weight: 700;
  line-height: 2.4rem;
  font-size: 1.8rem;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid rgba(24, 20, 24, 0.08);
  outline: none;
  transition: all 0.3s linear;

  ${MEDIA.TABLET`
    line-height: 3.2rem;
    font-size: 2.4rem;
  `}

  &::placeholder {
    font-weight: 300;
    color: rgba(24, 20, 24, 0.6);
  }

  &:hover,
  &:focus {
    outline: none;
    border: 1px solid #181418;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'textarea':
        return css`
          height: 24rem;

          ${MEDIA.TABLET`
            height: 32rem;
          `}
        `;
    }
  }}
`;

export default Input;
