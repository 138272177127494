import React from 'react';

import getServiceColor from 'helpers/getServiceColor';

import Section from 'components/UI/Section/Section';
import Card from 'components/UI/Card/Card';
import Text from 'components/UI/Text/Text';
import ServicesList from 'components/Services/List';
import ContentfulRichText
  from 'components/UI/ContentfulRichText/ContentfulRichText';

import { StyledList, StyledText, StyledButton } from './styled';

const ServiceItem = ({
  node: {
    id,
    typeOfService,
    title,
    subtitle
  }
}) => {
  const anchor = typeOfService.replace(' ', '-').toLowerCase();

  return (
    <Card
      key={id}
      title={title}
      subtitle={subtitle}
      linkText="More +"
      href={`/services#${anchor}`}
      color={getServiceColor(typeOfService)}
    />
  );
}

const Services = ({ data }) => (
  <Section>
    <ServicesList
      title="Services"
      titleAs="h2"
      data={data}
      additionalContent={(

        <Card
          title={
            <StyledButton as="a" variant="primary">
              <StyledText>Explore more</StyledText>
            </StyledButton>
          }
          href="/services"
          promo
        >
        </Card>
      )}
    />
  </Section>
);

export default Services;
