import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/UI/Text/Text';
import { StyledItem, StyledText, StyledLink } from './styled';

const HeaderMainItem = ({ title, text, link, href }) => (
  <StyledItem>
    <Text as="h5" dark>{title}</Text>
    <StyledText>{text}</StyledText>
    <StyledLink to={href}>{link}</StyledLink>
  </StyledItem>
);

HeaderMainItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default HeaderMainItem;
