import styled, { css } from 'styled-components';
import Text from 'components/UI/Text/Text';
import Button from 'components/UI/Button/Button';
import MEDIA from 'helpers/mediaTemplates';

export const StyledTitle = styled.h3`
  position: relative;
  padding: 1.2rem 0 1.2rem 3.2rem;
  margin-bottom: 4.4rem;
  font-weight: 700;
  line-height: 2.8rem;
  font-size: 2.4rem;
  letter-spacing: -0.05rem;
  color: #181418;
  text-transform: uppercase;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0.8rem;
    height: 4.8rem;
    background-color: #181418;
  }

  ${MEDIA.TABLET_XL`
    margin-bottom: 9.6rem;
    padding: 2rem 0 2rem 5.6rem;
    line-height: 5.6rem;
    font-size: 4.8rem;

    &::before {
      width: 1.6rem;
      height: 9.6rem;
    }
  `}
`;

export const StyledForm = styled.form`
  width: 100%;

  ${MEDIA.TABLET_XL`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `}
`;

export const StyledLabel = styled(Text)`
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 2.2rem;

  ${MEDIA.TABLET_XL`
    width: 48.6%;
    margin-bottom: 3.2rem;
    font-size: 24px;
    line-height: 32px;
  `}
`;

export const StyledButton = styled(Button)`
  text-transform: uppercase;

  ${MEDIA.TABLET_XL`
    width: 25.6rem;
  `}
`;
