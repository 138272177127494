import styled from 'styled-components';
import Button from 'components/UI/Button/Button';
import MEDIA from 'helpers/mediaTemplates';

export const StyledSlogan = styled.section`
  background-color: #181418;
`;

export const StyledWrapper = styled.div`
  max-width: 144rem;
  margin: 0 auto;
  padding: 9.6rem 1.7rem;

  ${MEDIA.TABLET_XL`
    padding: 12.8rem 1.7rem;
  `}
`;

export const StyledText = styled.p`
  font-weight: 400;
  line-height: 2.4rem;
  font-size: 1.8rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1.6rem;

  ${MEDIA.TABLET_XL`
    line-height: 3.2rem;
    font-size: 2.4rem;
    margin-bottom: 6.4rem;
  `}
`;

export const StyledButton = styled(Button)`
  margin: 0 auto;
  line-height: 2.4rem;
  font-size: 1.8rem;
  text-transform: none;

  ${MEDIA.TABLET_XL`
    width: 19.9rem;
    line-height: 3.2rem;
    font-size: 2.4rem;
    padding: 1.7rem;
  `}
`;
