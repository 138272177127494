import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout/Layout';
import HeaderMain from 'components/HeaderMain/HeaderMain';
import AboutUs from 'components/Team/AboutUs/AboutUs';
import Partners from 'components/Partners/Partners';
import Services from 'components/Services/Promo';
import Slogan from 'components/Slogan/Slogan';
import Team from 'components/Team/Promo';
import Clients from 'components/Clients/Promo';
import Contacts from 'components/Contacts/Promo';

const Index = ({ data }) => (
  <Layout page="home">
    <HeaderMain data ={data.allContentfulHeroImage.edges} />
    <AboutUs />
    <Partners data={data.allContentfulFeaturedPartners.edges} />
    <Services data={data.allContentfulService.edges} />
    <Slogan />
    <Team data={data.allContentfulPeople.edges}/>
    <Clients data={data.allContentfulCurrentClients.edges}/>
    <Contacts />
  </Layout>

);

export default Index;

export const query = graphql`
  query {
    allContentfulFeaturedPartners{
      edges {
        node {
          name
          logoInSvg {
            file { url }
          }
        }
      }
    },
    allContentfulPeople (sort:{fields:orderNumber}, filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          name
          photo {file {url}}
          jobTitle
          id
          linkedin
        }
      }
    },
    allContentfulHeroImage(sort:{fields:orderNumber}){
      edges{
        node{
          heroText
          heroImage{file{url}}
          typeOfBusiness
          link
        }
      }
    },
    allContentfulCurrentClients{
      edges{
        node{
          name
          logoInSvg{file{url}}
        }
      }
    },
    allContentfulService(
      filter: {
        showOnMain: { eq: true }
      }
    ) {
      pageInfo { hasNextPage }
      totalCount
      edges {
        node {
          id
          typeOfService
          title
          subtitle
          description { json }
        }
      }
    }
  }
`;
