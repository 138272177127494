import React from 'react';
import Section from 'components/UI/Section/Section';
import Slider from 'components/UI/Slider/Slider';
import Text from 'components/UI/Text/Text';

import People from './People';
import {
  StyledButton,
  StyledControl,
} from './styled';
import Arrow from './images/Arrow';
import TEAMS from './data';

const Team = ({data}) => {
  const sliderProps = {
    prevArrow: <StyledControl variant="left" />,
    nextArrow: <StyledControl variant="right" />,
    rows: 2,
    slidesPerRow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          rows: 2,
          slidesPerRow: 3,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 740,
        settings: {
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };

  return (
    <Section>
      <Text as="h2" line>Our Team</Text>
      <Slider {...sliderProps}>
        {data.map(item => (<People {...item.node} key={item.id} />))}
      </Slider>
      <StyledButton href="/team" as="a" variant="outline">
        <Text>Our Advisory Board</Text>
        <Arrow />
      </StyledButton>
    </Section>
  );
};

export default Team;
