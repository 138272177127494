import photo_1 from './photo-1.jpg';
import photo_2 from './photo-2.jpg';
import photo_3 from './photo-3.jpg';
import photo_4 from './photo-4.jpg';
import photo_5 from './photo-5.jpg';
import photo_6 from './photo-6.jpg';
import photo_7 from './photo-7.jpg';
import photo_8 from './photo-8.jpg';
import photo_9 from './photo-9.jpg';
import photo_10 from './photo-10.jpg';

export default [
  { src: photo_1, name: 'Mario Cohen', position: 'SEO & Founder' },
  {
    src: photo_2,
    name: 'Leonardo González',
    position: 'Commercial Director',
  },
  { src: photo_3, name: 'Sabina Iman', position: 'Strategy Director' },
  {
    src: photo_4,
    name: 'Oscar Onel',
    position: 'Marketing & Comm Director',
  },
  {
    src: photo_5,
    name: 'Juan Jozé Rodriuez',
    position: 'Open Innovation Director',
  },
  {
    src: photo_6,
    name: 'Carlos Gutiérrez',
    position: 'Open Innovation VCs & ISV Director',
  },
  {
    src: photo_7,
    name: 'Natalia Fotohabadi',
    position: 'Business Development',
  },
  {
    src: photo_8,
    name: 'Rudolf Zimmermann',
    position: 'Senior Sales Manager',
  },
  {
    src: photo_9,
    name: 'Guillermo Aranda',
    position: 'Senior Sales Manager',
  },
  {
    src: photo_10,
    name: 'Estefanía Gambin',
    position: 'Sales Manager',
  },
];
