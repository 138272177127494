import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import Text from 'components/UI/Text/Text';

const StyledRoot = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  cursor: pointer;
`;
const Input = styled.input`
  appearance: none;
  height: 30px;
  width: 30px;
  border: 1px solid #181418;
  background-color: #fff;
  margin: 0 14px 0 0;
  outline: none;
  cursor: pointer;

  ${({ checked }) => checked && css`
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0%0APSIyNCI+PHBhdGggZD0iTTIwLjI4NSAyTDkgMTMuNTY3IDMuNzE0IDguNTU2IDAgMTIuMjcyIDkg%0AMjEgMjQgNS43MTV6Ii8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
  `}
`;

export default function ({ id, label, onChange, ...props }) {
  const [isChecked, setChecked] = useState(false);

  const handleChange = ({ target: { checked } }) => {
    setChecked(checked);
    onChange && onChange(checked);
  };

  return (
    <StyledRoot>
      <Input
        id={id}
        type="checkbox"
        {...props}
        checked={isChecked}
        onChange={handleChange}
      />
      <Text as="label" htmlFor={id}>{label}</Text>
    </StyledRoot>
  );
}
