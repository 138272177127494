import React from 'react';
import Section from 'components/UI/Section/Section';
import Scale from '../Scale/Scale';
import Text from 'components/UI/Text/Text';

const AboutUs = () => {
  return (
    <Section>
      <Text as="h2" line>
        About Us
      </Text>
      <Text variant="desc">
        Demtech is an Open Innovation Specialist for corporations, Investment
        Consultancy Partner for Venture Capital Firms, and Sales & Business
        Developer for High-Tech Companies; With expertise in accelerating
        clients’ expansion plans through fast cost-saving business development
        solutions and growth into European and Latin American markets:
        Facilitating the transformation of innovative concepts into marketable
        services for our clients allowing them to achieve an efficient growth.
      </Text>
      <Scale />
    </Section>
  );
};

export default AboutUs;
