import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Button from 'components/UI/Button';



export const StyledServices = styled.section`
  position: relative;
  max-width: 144rem;
  margin: 0 auto;
  padding: 11.2rem 1.7rem 9.6rem;

  ${MEDIA.TABLET`
    padding: 11.2rem 3rem 7rem;
  `}

  ${MEDIA.TABLET_XL`
    padding: 18.4rem 5rem 16.4rem;

    &::before {
      left: 5rem;
      top: 12.6rem;
    }
  `}

  ${MEDIA.DESKTOP`
    padding: 18.4rem 12rem 16.4rem;

    &::before {
      left: 12rem;
    }
  `}

  ${MEDIA.DESKTOP_XL`
    padding: 18.4rem 16rem 14rem;

    &::before {
      left: 16rem;
    }
  `}
`;

export const StyledList = styled.ul`
  list-style: none;

  ${MEDIA.TABLET_XL`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  `}
`;

export const StyledButton = styled(Button)`
  margin: 0 auto;

  ${MEDIA.TABLET_XL`
    position: absolute;
    left: 6rem;
    bottom: 9rem;
    margin: 0;

    & svg {
      width: 28px;
      height: 16px;
    }
  `}

  ${MEDIA.DESKTOP`
    left: 12rem;
  `}

  ${MEDIA.DESKTOP_XL`
    left: 16rem;
  `}
`;

export const StyledText = styled.span`
  margin-right: 1rem;
`;
