import React, { Component } from 'react';
import { StyledItem, StyledYear, StyledList } from './styled';

const SCALE = [
  { year: '2000', text: 'Founded Madrid HQ' },
  { year: '2003', text: 'Expansion to LATAM' },
  { year: '2007', text: 'Operations to UK' },
  { year: '2015', text: 'Creation of the Open Innovation Business Unit' },
  { year: '2017', text: 'Reached 400+ Clients' },
  { year: '2018', text: 'Expansion of Business Lines' },
  {
    year: 'Future',
    text:
      'The leaders in generating holistic integrated services for the tech ecosystem',
  },
];

class Scale extends Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    if (this.scroller) {
      if (removeEventListener) {
        this.scroller.removeEventListener('DOMMouseScroll', this.onWheel);
        this.scroller.removeEventListener('mousewheel', this.onWheel);
      } else {
        this.scroller.detachEvent('onmousewheel', this.onWheel);
      }
    }
  }

  onRef(scroller) {
    if (this.scroller) {
      return false;
    }

    this.scroller = scroller;
    this.onWheel = e => this.onMouseWheel(e);

    if (scroller.addEventListener) {
      // FireFox
      scroller.addEventListener('DOMMouseScroll', this.onWheel, false);
      // other
      scroller.addEventListener('mousewheel', this.onWheel, false);
    } else {
      // IE
      scroller.attachEvent('onmousewheel', this.onWheel);
    }
  }

  onMouseWheel(e = window.event) {
    const horizontal = Math.abs(e.deltaX) > Math.abs(e.deltaY);

    if (horizontal) {
      return;
    }

    // cross-browser wheel delta
    const delta = -25 * Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
    const { offsetWidth, scrollLeft, scrollWidth } = this.scroller;
    const rightLimit = scrollWidth - offsetWidth;

    const pst = scrollLeft + delta;

    if (pst < 0 || pst > rightLimit) {
      return;
    }

    this.scroller.scrollLeft = pst;
    e.preventDefault();
    return false;
  }

  render() {
    return (
      <StyledList ref={el => this.onRef(el)}>
        {SCALE.map(item => (
          <StyledItem key={item.year} as="li" variant="smallTitle">
            <StyledYear>{item.year}</StyledYear>
            {item.text}
          </StyledItem>
        ))}
      </StyledList>
    );
  }
}

export default Scale;
