import styled from 'styled-components';

import Section from 'components/UI/Section/Section';
import Text from 'components/UI/Text/Text';
import Button from 'components/UI/Button/Button';
import MEDIA from 'helpers/mediaTemplates';

export const StyledSection = styled(Section)`
  background-color: #181418;

  ${MEDIA.TABLET`
    display: flex;
    align-items: center;
  `}
`;

export const StyledTitle = styled(Text)`
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 4rem;
  text-align: center;

  ${MEDIA.TABLET`
    max-width: 30%;
    text-align: left;
  `}

  ${MEDIA.TABLET_XL`
    width: 18.8rem;
    margin-bottom: 60px;
    margin-right: 5.3%;
    text-align: left;
  `}
`;

export const StyledItem = styled.li`
  display: inline-flex !important;
  align-items: center;
  height: 200px;
  margin-bottom: 0.5rem;

  ${MEDIA.TABLET_XL`
    width: 31%;
    margin-bottom: 0;
    margin-top: 1rem;

    &:first-of-type,
    &:nth-of-type(2),
    &:nth-of-type(3) {
      margin-top: 0;
    }
  `}
`;

export const StyledImg = styled.img`
  max-width: 80%;
  max-height: 80%;
`;

export const StyledButton = styled(Button)`
  margin: 0 auto;

  ${MEDIA.TABLET`
    position: absolute;
    left: 50px;
    bottom: 20px;
  `}

  ${MEDIA.TABLET_XL`
    bottom: 30px;

    & svg {
      width: 28px;
      height: 16px;
    }
  `}

  ${MEDIA.DESKTOP`
    left: 160px;
    bottom: 120px;
  `}
`;

export const StyledText = styled.span`
  margin-right: 1rem;
`;
