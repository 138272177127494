import React from 'react';

import Form from 'components/Form/Form';
import Text from 'components/UI/Text/Text';
import Section from 'components/UI/Section/Section';

import ContactsMap from './Map';
import List from './List';

const Contacts = () => (
  <Section id="contacts">
    <Text as="h2" line>Let’s get in touch!</Text>
    <List />
    <ContactsMap />
    <Form id="contact_form" />
  </Section>
);

export default Contacts;
