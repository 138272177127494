import React from 'react';

import Section from 'components/UI/Section/Section';
import Text from 'components/UI/Text/Text';
import {
  StyledButton,
  StyledText,
} from './styled';

const Slogan = () => {
  return (
    <Section promo fullWidth>
      <Text as="h3" dark centered mb={20} mt={20}>
        Market entry timing matters. Contact&nbsp;Us!
      </Text>
      <StyledText>Our managers will contact you ASAP</StyledText>
      <StyledButton variant="primary" as="a" href="#contact">
        Contact Us
      </StyledButton>
    </Section>
  );
};

export default Slogan;
