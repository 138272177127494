import styled from 'styled-components';
import Text from 'components/UI/Text/Text';
import MEDIA from 'helpers/mediaTemplates';
import Link from 'components/UI/Link/Link';

// const bg = 'http://images.ctfassets.net/hfduozb8gcp0/1l45n0mHt1xBPmPmsMSdxQ/ef0041f09b112d6e2b4974ecc44d3ec9/bg-4a72abee710231c90fb6c2771a316060.png';
// const bg2 = 'https://images.unsplash.com/photo-1523780329224-c3be49f59edd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80';
// import bg3 from './icons/bg3.png';

// const heroUrls = { bg, bg2, bg3}
// const BG_IMAGES = { bg, bg2, bg3 };

export const StyledHeaderMain = styled.div`
  position: relative;
  background-color: #181418;
`;

export const StyledSlider = styled.div`
  width: 100%;
`;

export const StyledTitle = styled(Text)`
  width: 32.6rem;
  display: inline;
  color: #fff;
  padding: 1rem;
  list-style: none;

  &::before {
    content: none;
  }

  ${MEDIA.TABLET`
    width: 40rem;
  `}

  ${MEDIA.TABLET_XL`
    width: auto;
  `}

  ${MEDIA.DESKTOP`
    width: 100.4rem;
  `}
`;

export const StyledListWrapper = styled.div`
  display: flex;
  justify-content: flex-end !important;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(rgba(100,100,100,0),rgba(0,0,0,.9));


  position: absolute;
  bottom: 0;
  z-index: 10;

  min-height: 365px;
  width: 100%;
  padding: 1.7rem 2.4rem 0;

  ${MEDIA.TABLET`
    padding: 3rem 2.4rem 0;
  `}

  ${MEDIA.TABLET_XL`
    padding: 5rem 2.4rem 0;
  `}

  ${MEDIA.DESKTOP`
    padding: 12rem 3.2rem 0;
  `}

  ${MEDIA.DESKTOP_XL`
    padding: 16rem 3.2rem 0;
  `}
`;

export const StyledList = styled.ul`
  max-width: 144rem;
  margin: 80px auto 0;
  list-style: none;
  width:100%;

  ${MEDIA.TABLET_XL`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
  `}
`;

export const StyledHeroItem = styled.div`
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  height: 100vh;
  max-height: 1000px;
  padding: 10%;
  padding-bottom: 250px;

  background: url(${({ bg }) => bg}) no-repeat center;
  background-size: cover;

  ${MEDIA.PHONE`
    padding-top: 80px;
  `}

  ${MEDIA.TABLET`
    padding-top: 100px;
    padding-bottom: 500px;
  `}

  ${MEDIA.TABLET_XL`
    padding-top: 100px;
    padding-bottom: 250px;
  `}
`;
export const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;
