import styled from 'styled-components';
import Text from 'components/UI/Text/Text';
import Button from 'components/UI/Button/Button';
import MEDIA from 'helpers/mediaTemplates';

export const StyledClients = styled.section`
  background-color: #181418;
`;

export const StyledWrapper = styled.div`
  position: relative;
  max-width: 144rem;
  margin: 0 auto;
  padding: 9.8rem 1.7rem 8rem;

  ${MEDIA.TABLET`
    padding: 9.8rem 3rem 8rem;
  `}

  ${MEDIA.TABLET_XL`
    display: flex;
    padding: 12.6rem 5rem 12.8rem;
  `}

  ${MEDIA.DESKTOP`
    padding: 12.6rem 10rem 12.8rem 12rem;
  `}

  ${MEDIA.DESKTOP_XL`
    padding: 12.6rem 10rem 12.8rem 16rem;
  `}
`;

export const StyledTitle = styled(Text)`
  margin-top: 0;
  margin-bottom: 4rem;
  text-align: center;

  ${MEDIA.TABLET_XL`
    width: 22.8rem;
    margin-bottom: 0;
    margin-right: 5.3%;
    text-align: left;
  `}
`;

export const StyledItem = styled.li`
  margin-bottom: 0.5rem;
  text-align: center;
  min-height:100px;
  display: inline-flex !important;
  align-items: center;
`;

export const StyledImg = styled.img`
  margin: 10px auto;
  max-width: 80%;
  max-height: 80%;
`;

export const StyledButton = styled(Button)`
  margin: 0 auto;

  ${MEDIA.TABLET_XL`
    position: absolute;
    left: 5rem;
    bottom: 12.8rem;
    margin: 0;

    & svg {
      width: 28px;
      height: 16px;
    }
  `}

  ${MEDIA.DESKTOP`
    left: 12rem;
  `}

  ${MEDIA.DESKTOP_XL`
    left: 16rem;
  `}
`;

export const StyledText = styled.span`
  margin-right: 1rem;
`;

export const StyledImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff url(${({ src }) => src}) no-repeat center;
  background-size: 70%;
`;
