import React from 'react';
import { StyledTitle, StyledForm, StyledLabel, StyledButton } from './styled';
import Input from 'components/UI/Input/Input';
import Checkbox from 'components/UI/Checkbox/Checkbox';

class Form extends React.Component {
  state = {
    name: '',
    email: '',
    company: '',
    position: '',
    message: '',
    isDisabled: true,
  };

  fieldOnChange = fname => event => {
    this.setState({ [fname]: event.target.value });
  };

  onCheck = checked => {
    this.setState({ isDisabled: !checked });
  };

  render() {
    const { isDisabled } = this.state;

    return (
      <React.Fragment>
        <StyledTitle>Write us!</StyledTitle>
        <StyledForm
          name="contact"
          action="/success"
          method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="contact" />
          <StyledLabel as="label">
            Name
            <Input
              type="text"
              placeholder="name"
              name="nickname"
              value={this.state.name}
              onChange={this.fieldOnChange('name')}
              required
            />
          </StyledLabel>
          <StyledLabel as="label">
            Email
            <Input
              type="email"
              placeholder="example@gmail.com"
              name="email"
              value={this.state.email}
              onChange={this.fieldOnChange('email')}
              required
            />
          </StyledLabel>
          <StyledLabel as="label">
            Company
            <Input
              type="text"
              placeholder="company name"
              name="company name"
              value={this.state.company}
              onChange={this.fieldOnChange('company')}
              required
            />
          </StyledLabel>
          <StyledLabel as="label">
            Position
            <Input
              type="text"
              placeholder="e.g. managing director"
              name="position"
              value={this.state.position}
              onChange={this.fieldOnChange('position')}
              required
            />
          </StyledLabel>
          <StyledLabel
            as="label"
            style={{ width: '100%', marginBottom: 44 }}
          >
            Message
            <Input
              as="textarea"
              variant="textarea"
              type="text"
              placeholder="your message here"
              name="message"
              value={this.state.message}
              onChange={this.fieldOnChange('message')}
              required
            />
          </StyledLabel>
          <Checkbox
            id="contact-gdpr"
            label={<label>I agree to the <a href='/privacy-policy' target='_blank'>Terms of use and the Privacy Policy</a></label>}
            onChange={this.onCheck}
          /><br />
          <StyledButton type="submit" variant="outline" disabled={isDisabled}>
            Submit
          </StyledButton>
        </StyledForm>
      </React.Fragment>
    );
  }
}

export default Form;
